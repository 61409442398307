import {
  Container,
  Image,
  Group,
  AppShell,
  UnstyledButton,
  Button,
  Box,
  type BoxProps,
  Skeleton,
  Text,
  Divider,
  Title,
  Loader,
} from "@mantine/core";
import * as m from "@/paraglide/messages";
import { Footer } from "@/components/landing/Footer";
import posthog from "posthog-js";
import { useUser } from "@clerk/nextjs";
import { Breadcrumb, LanguagePicker } from "@/components";
import { UserButton } from "@clerk/nextjs";
import Link from "next/link";
import { useRouter } from "next/router";
import { IconSettingsFilled } from "@tabler/icons-react";
import { WeightProvider } from "@/components/patientPage/WeightProvider";

const UserPrefs = () => {
  return (
    <div>
      <Title order={4}>{m.steep_suave_shrike_favor()}</Title>
      <Divider my="md" />
      <Title order={5} mb="sm">
        {m.each_house_worm_bubble()}
      </Title>
      <Text mb="sm">{m.frail_factual_hare_amaze()}</Text>
      <WeightProvider shouldTriggerPrefUpdate size="md" />
    </div>
  );
};
export function Layout({
  children,
  displayMyPatientsButton = true,
  displaySignInButton = true,
  displayCalcButton = false,
  hideHeader = false,
  hideAlmaIcon = false,
  ...boxProps
}: BoxProps & {
  children: React.ReactNode | React.ReactNode[];
  displayMyPatientsButton?: boolean;
  displaySignInButton?: boolean;
  displayCalcButton?: boolean;
  hideHeader?: boolean;
  hideAlmaIcon?: boolean;
}) {
  const router = useRouter();
  const { isLoaded, isSignedIn } = useUser();

  return (
    <>
      <AppShell
        header={{ height: { base: 48, md: 60 } }}
        footer={{ height: { base: 48 } }}
        padding="md"
        style={{
          minHeight: "100vh",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <AppShell.Header bg="gray.1" hidden={hideHeader}>
          <Container h="100%" size="xl">
            <Group h="100%" justify="space-between">
              {!hideAlmaIcon && (
                <UnstyledButton tabIndex={-1} component="a" href="/" maw={100}>
                  <Image
                    src="/logos/full-logo-blue.svg"
                    alt={m.true_quiet_grizzly_enjoy()}
                    height={28}
                    width={100}
                  />
                </UnstyledButton>
              )}

              <Group gap={8}>
                {!isLoaded && (
                  <>
                    <Skeleton h={24} w={120}></Skeleton>
                    <Skeleton height={36} circle></Skeleton>
                  </>
                )}

                {displaySignInButton && isLoaded && !isSignedIn && (
                  <Button
                    // onClick={() => clerk.openSignIn()}
                    onClick={() => router.push("/sign-in")}
                    // radius="lg"
                    size="compact-sm"
                  >
                    {m.bold_tired_baboon_lift()}
                  </Button>
                )}
                {displayMyPatientsButton && isLoaded && isSignedIn && (
                  <Button
                    tabIndex={-1}
                    component={Link}
                    href={"/app/patients"}
                    size="compact-sm"
                  >
                    {m.large_equal_emu_dig()}
                  </Button>
                )}
                {displayCalcButton && isLoaded && (
                  <Button
                    tabIndex={-1}
                    component={Link}
                    href={"/#calc-section"}
                    size="compact-sm"
                    variant="light"
                    onClick={() => posthog.capture("use_calc_without_patient")}
                  >
                    {m.loose_steep_herring_renew()}
                  </Button>
                )}
                <LanguagePicker type="collapsed" size={30} />
                {isLoaded && (
                  <UserButton signInUrl="/sign-in" userProfileMode="modal">
                    <UserButton.UserProfilePage
                      label={m.steep_suave_shrike_favor()}
                      url="prefs"
                      labelIcon={
                        <IconSettingsFilled size="16px" color="white" />
                      }
                    >
                      <UserPrefs />
                    </UserButton.UserProfilePage>
                  </UserButton>
                )}
              </Group>
            </Group>
          </Container>
        </AppShell.Header>

        <AppShell.Main pb={20} mih="auto">
          <Container
            h="100%"
            size="xl"
            p="0"
            mih={{ base: "calc(100dvh - 624px)", md: "calc(100dvh - 592px)" }}
            pos="relative"
          >
            <Box {...boxProps}>
              <Breadcrumb />
              {children}
            </Box>
          </Container>
        </AppShell.Main>

        <Footer containerSize="xl" style={{ marginTop: "auto" }} />
      </AppShell>
    </>
  );
}
